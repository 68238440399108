import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "signin",
    component: () => import("../views/SignIn.vue"),
  },
  {
    path: "/otp",
    name: "otp",
    component: () => import("../views/Otp.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/create",
    name: "create",
    component: () => import("../views/Create.vue"),
  },
  {
    path: "/videos",
    name: "videos",
    component: () => import("../views/Videos.vue"),
  },
  {
    path: "/video/:guid",
    name: "video",
    component: () => import("../views/VideoDetail.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
